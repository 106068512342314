import.meta.glob([
    '../images/**',
    '../css/customize_1.css',
  ]);

/*
// Import our custom CSS
import '../sass/app.scss'
*/
import 'vite/modulepreload-polyfill'

try {
    window.$ = window.jQuery = require('jquery');
    window.jquery_circle_progress = require('jquery-circle-progress');
} catch (e) {
}

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        "X-Requested-With": "XMLHttpRequest"
    }
});
